import React from "react"
import { graphql } from "gatsby"
import AppBar from '../../components/appBar'
import Img from "gatsby-image"
import Typography from '@material-ui/core/Typography';


const Post = ({ data: { prismicTourpackagedetail } }) => {
  const { data } = prismicTourpackagedetail
  return (
    <React.Fragment>
      <AppBar />
      <Img
        fluid={data.tourdetailimage.localFile.childImageSharp.fluid}
      />
      <Typography variant="h2" >
        {data.tourtitle.text}
      </Typography>
      <Typography variant="body1" >

        {data.tourdescription.text}
      </Typography>


    </React.Fragment>
  )
}

export default Post

export const pageQuery = graphql`
  query tourdetail($uid: String!) {
        prismicTourpackagedetail (uid: { eq: $uid }){
            uid
            data {
              tourtitle {
                text
              }
              tourdescription {
                text
              }
              tourdetailimage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
  }
  `